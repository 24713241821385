<template>
  <div class="home flex-1 flex flex-column">
    <div id="navbar" :class="navClass" class="md:align-self-center flex flex-row fixed z-3 pl-2 pr-2" >
      <img class="mt-1 mr-1 mb-1" style="max-width:172px" src="../assets/logoluxibud_withouttext.png" />
      <span class="md:mr-5 flex align-items-center text-2xl">ARTEAM</span>
      

      <button class="p-link layout-topbar-menu-button layout-topbar-button"
        v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', 
        leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true}">
        <i class="pi pi-bars"></i>
      </button>

      <ul class="layout-topbar-menu hidden sm:flex origin-top">
        <li>
          <div @click="goto('firstview')" :style="currentview == 'contact' ? 'background-color:var(--surface-hover)' : ''"
              class="navbar-item flex align-items-center cursor-pointer p-ripple h-full" v-ripple>Wizytówka</div>
        </li>
        <li>
          <div @click="goto('aboutus')" :style="currentview == 'aboutus' ? 'background-color:var(--surface-hover)' : ''"
                class="navbar-item flex align-items-center cursor-pointer p-ripple h-full" v-ripple>O nas</div>
        </li>
        <li>
          <div @click="goto('products')" :style="currentview == 'products' ? 'background-color:var(--surface-hover)' : ''"
              class="navbar-item flex align-items-center cursor-pointer p-ripple h-full" v-ripple>Realizacje</div>
        </li>
    </ul>
      
      <div class="flex flex-row flex-grow-1 justify-content-end">
        <Button icon="pi pi-facebook" @click="openLink('https://www.facebook.com/arteamwykonczeniewnetrz')" class="p-button-rounded p-button-text p-button-lg align-self-center" />
        <Button icon="pi pi-instagram" @click="openLink('https://www.instagram.com/roman.arteam')" class="p-button-rounded p-button-text p-button-lg align-self-center" />
      </div>
      
    </div>
    <div id="firstview" ref="firstview" class="pt-6 min-h-screen w-full flex flex-column justify-content-center align-items-center">
      <InfoCard @readmore="withTimeout(goto('aboutus'))" />
    </div>
    <div ref="aboutus" id="aboutus" class="min-h-screen justify-content-between w-full relative flex pt-8 flex-column">
      <AboutUs @products="withTimeout(goto('products'))"/>
      <img id="aboutus-img" src="../assets/podloga.webp" class="bottom-0 right-0" alt="hero screen">
    </div>
    <div ref="products" id="products" class="min-h-screen w-full flex pt-8 flex-column align-items-center justify-content-center">
      <!-- <Products /> -->
      <Box id="product-box" :items="images" />
      <div class="w-full h-5rem footer flex align-items-center" ref="footer">
        <Carousel class="max-h-full max-w-full" :value="brands" :numVisible="8" :numScroll="8" :responsiveOptions="responsiveOptions"
        indicatorsContentClass="hidden"
        :autoplayInterval="4000"
        :circular="true">
                <template #item="slotProps">
                    <div class="product-item h-full flex justify-content-center">
                        <img style="display:block; max-height:4rem; max-width: 150px; height: auto; width: auto;" :src="slotProps.data">
                    </div>
                </template>
            </Carousel>
      </div>
    </div>

  </div>
</template>
<script>
import Ripple from 'primevue/ripple';
// import Products from '@/components/Products.vue'
import Box from '@/components/Box.vue'
import InfoCard from '@/components/InfoCard.vue'
import Button from 'primevue/button/sfc'
import AboutUs from '@/components/AboutUs.vue'
import Carousel from 'primevue/carousel/sfc'
export default {
  name: 'Home',
  components: {
    //Products,
    Box,
    InfoCard,
    Button,
    AboutUs,
    Carousel
  },
  directives: {
    'ripple': Ripple
  },
  data() {
    return {
      responsiveOptions: [
      {
				breakpoint: '1200px',
				numVisible: 6,
				numScroll: 6
			},
			{
				breakpoint: '1024px',
				numVisible: 5,
				numScroll: 5
			},
			{
				breakpoint: '800px',
				numVisible: 3,
				numScroll: 3
			},
      {
				breakpoint: '600px',
				numVisible: 2,
				numScroll: 2
			},
		],
      brands: [
        "/producers/1.jpg",
        "/producers/2.jpg",
        "/producers/3.jpg",
        "/producers/4.jpg",
        "/producers/5.jpg",
        "/producers/6.jpg",
        "/producers/7.jpg",
        "/producers/8.jpg",
        "/producers/9.jpg",
        "/producers/10.jpg",
        "/producers/11.jpg",
        "/producers/12.jpg",
        "/producers/13.jpg",
        "/producers/14.jpg",
        "/producers/15.jpg",

      ],
      images: [
          "/galleria/01.webp",

          "/galleria/02.webp",


          "/galleria/03.webp",

          "/galleria/04.webp",

          "/galleria/05.webp",

          "/galleria/07.webp",

          "/galleria/08.webp",
          "/galleria/09.webp",
          "/galleria/10.webp",

      ],
      navClass: "p-card w-full md:w-11 h-5rem border-noround md:border-round navbar-float",
      currentview: "",
      lookAtScrollView: true,
    }
  },
  methods: {
    openLink(link){
      window.open(link, '_blank')
    },
    handleScroll() {
      let offset = window.pageYOffset
      if (offset > 0) {
        this.navClass = "p-card w-full h-5rem navbar-sticked border-noround"
      } else {
        this.navClass = "p-card w-full md:w-11 h-5rem border-noround md:border-round navbar-float"
      }
      let productsTop = this.$refs["products"].offsetTop
      let aboutusTop = this.$refs["aboutus"].offsetTop
      if (this.lookAtScrollView) {
        let currentview = "firstview"
        if (offset >= productsTop) {
          currentview = "products"
        } else if (offset >= aboutusTop && offset < productsTop) {
          currentview = "aboutus"
        }
        this.currentview = currentview
      }
    },
    withTimeout(fn){
      setTimeout(fn,25)
    },
    smoothScroll(top) {
      window.scrollTo({
        top: top,
        left: 0,
        behavior: 'smooth'
      });
      return new Promise((resolve, reject) => {
        const failed = setTimeout(() => {
          reject();
        }, 2000);

        const scrollHandler = () => {
          if (window.pageYOffset === top) {
            window.removeEventListener("scroll", scrollHandler);
            clearTimeout(failed);
            resolve();
          }
        };
        if (window.pageYOffset === top) {
          clearTimeout(failed);
          resolve();
        } else {
          window.addEventListener("scroll", scrollHandler);
        }
      });
    },
    goto(refName) {
      this.lookAtScrollView = false
      var element = this.$refs[refName];
      var top = element.offsetTop;
      this.currentview = refName
      this.smoothScroll(top).finally(() => {
        this.lookAtScrollView = true
      })
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll)
  },
}
</script>
<style lang="scss">
#product-box {
  height: calc(100vh - 10rem);
}
#products{
    background: linear-gradient(0deg,hsla(0,0%,100%,.2),hsla(0,0%,100%,.2)),radial-gradient(77.36% 256.97% at 77.36% 57.52%,#eeefaf 0,#c3e3fa 100%);
}
@media screen and (min-width: 1400px) {
  #product-box {
    height: calc(100vh - 10rem);
    width: 70%;
  }
}
.navbar-float {
  top: 0px;
  background-color: rgba(30, 23, 47, 0.8) !important;
  max-width:1200px;
}
.navbar-item {
  padding: 0.5rem;
}
@media screen and (min-width: 768px) {
  .navbar-item {
    padding: 1rem;
  };
}
.navbar-item:hover {
  color: rgb(136, 58, 183)
}
@media screen and (min-width: 768px) {
  .navbar-float {
    top: 20px;
    background-color: rgba(30, 23, 47, 0.8) !important;
  };
}

.footer{
  background-color: rgba(30, 23, 47, 1) !important;
}

.navbar-sticked {
  top: 0px;
  background-color: rgba(30, 23, 47, 1) !important;
  max-width: 100%;
  /* background-color: rgba(30, 30, 30,0.8) !important; */
}

#firstview {
  /* background-image: url("../assets/bcg3.webp"); */
  background-image: url("../assets/tempbg.webp");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
#aboutus {
  /* background-color:#efefef */
  background: linear-gradient(0deg,hsla(0,0%,100%,.2),hsla(0,0%,100%,.2)),radial-gradient(77.36% 256.97% at 77.36% 57.52%,#eeefaf 0,#c3e3fa 100%);
  
}

#aboutus-img{
  height: 350px;
  object-fit: cover;
  
}

@media screen and (min-width: 768px) {
    #aboutus{
        -webkit-clip-path: ellipse(150% 87% at 93% 13%);
        clip-path: ellipse(120% 87% at 93% 13%); 
    }
}
@media screen and (min-width: 1000px) {
    #aboutus-img{
      height: 480px;
    }
}
@media screen and (min-width: 1400px) {
    #aboutus-img{
      height: 550px;
    }
}
@mixin focused() {
    outline: 0 none;
    outline-offset: 0;
    transition: box-shadow .2s;
    box-shadow: var(--focus-ring);
}

.layout-topbar-menu {
  margin: 0 0 0 auto;
  padding: 0;
  list-style: none;
  display: flex;
  margin-left: 20px;

  .layout-topbar-button {
    margin-left: 1rem;
  }
}
.layout-topbar-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    position: relative;
    color: var(--text-color-secondary);
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    transition: background-color 0.2s;
    
    &:hover {
        color: var(--text-color);
        background-color: var(--surface-hover);
    }

    &:focus {
        @include focused();
    }

    i {
        font-size: 1.5rem;
    }

    span {
        font-size: 1rem;
        display: none;
    }
}
.layout-menu-button {
    margin-left: 2rem;
}

.layout-topbar-menu-button {
    display: none ;

    i {
        font-size: 1.25rem;
    }
}





@media (max-width: 576px) {

        .layout-menu-button {
            margin-left: 0;
        }

        .layout-topbar-menu-button {
            display: inline-flex;
            margin-left: 0;
        }

        .layout-topbar-menu {
            margin-left: 0;
            position: absolute;
            flex-direction: column;
            background-color:  rgba(30, 23, 47, 1) ;
            box-shadow: 0px 3px 5px rgba(0,0,0,.02), 0px 0px 2px rgba(0,0,0,.05), 0px 1px 4px rgba(0,0,0,.08);
            border-radius: 12px;
            padding: 1rem;
            left: 2rem;
            top: 5rem;
            min-width: 15rem;

            .layout-topbar-button {
                margin-left: 0;
                display: flex;
                width: 100%;
                height: auto;
                justify-content: flex-start;
                border-radius: 12px;
                padding: 1rem;

                i {
                    font-size: 1rem;
                    margin-right: .5rem;
                }

                span {
                    font-weight: medium;
                    display: block;
                }
            }
        }

        .navbar-float {
          .layout-topbar-menu {
            background-color: rgba(30, 23, 47, 0.8) !important;
          }
        }

}
</style>