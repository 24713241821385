import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "primevue/resources/themes/md-dark-indigo/theme.css";
import { createApp } from 'vue'
import PrimeVue from "primevue/config";
import App from './App.vue'
import StyleClass from 'primevue/styleclass';

import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill()


createApp(App).use(PrimeVue, {ripple: true}).directive('styleclass', StyleClass).mount('#app')
