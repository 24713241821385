<template lang="">
      <div id="bscard" style="background-color: rgba(30, 23, 47, 0.8) !important;"
        class="p-card mt-8 mb-8 z-2 p-3 flex flex-column md:flex-row justify-content-center">
        <div>
          <img class="img-logo" src="../assets/logoluxibud.png" />
        </div>
        <div class="hidden md:block">
          <Divider layout="vertical" />
        </div>
        <div class="flex flex-column p-3 justify-content-between">
          <div class="flex flex-column mb-3">
            <h3>KOMPLEKSOWE WYKOŃCZENIA WNĘTRZ</h3>
            <div class="mb-2">
              <Divider />
            </div>
            <span class="md:text-lg white-space-nowrap">
              <i class="pi pi-phone mr-2 mb-2" />
              <a href="tel:+48 510 451 111" style="color:inherit;text-decoration:inherit;">+48 510 451 111</a>
            </span>
            <span class="md:text-lg white-space-nowrap">
              <i class="pi pi-phone mr-2 mb-2" />
              <a href="tel:+48 731 391 033" style="color:inherit;text-decoration:inherit;">+48 731 391 033</a>
            </span>
            <span class="md:text-lg white-space-nowrap">
              <i class="pi pi-inbox mr-2 mb-2" />
              <a href="mailto:luxibud@wp.pl" style="color:inherit;text-decoration:inherit;">luxibud@wp.pl</a>
            </span>
            <span class="md:text-lg white-space-nowrap">
              <i class="pi pi-map-marker mr-2 mb-2" />
              <span>Warszawa</span>
            </span>
            <span class="md:text-lg white-space-nowrap">
              <i class="pi pi-home mr-2" />
              <span>05-077, Diamentowa 1/13</span>
            </span>
          </div>
          <Button @click="$emit('readmore')" type="button" class="p-button-outlined" style="colors:#F7B300">
            <div class="flex flex-column m-auto">
              <span class="md:text-lg mb-2 white-space-nowrap">Dowiedz się więcej</span>
              <i class="pi pi-chevron-down" />
            </div>
          </Button>
        </div>
      </div>
</template>
<script>
import Divider from 'primevue/divider/sfc';
import Button from 'primevue/button/sfc';
export default {
    name:"InfoCard",
    components:{
        Divider,
        Button
    },
    emits: ['readmore']
}
</script>
<style scoped>
.img-logo{
  width:100%;
  max-width:100px;
  height:auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 768px) {
  #bscard{
    margin-top: calc(5rem + 20px) !important;
    margin-bottom: calc(5rem + 20px) !important;
  }
  .img-logo{
    max-width: 300px !important;
  }
}
</style>