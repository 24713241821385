<template lang="">
  <div class="grid py-4 px-4 lg:px-8 h-full" id="hero">
      <div class="mx-4 md:mx-8 mt-0 md:mt-4">
          <h1 class="text-4xl font-bold text-gray-own line-height-2">
            <span class="font-light block">Kompleksowe usługi wykończeniowo-budowlane </span>
            8 lat doświadczenia na rynku
            <span class="font-light block">Od projektu do realizacji jedna umowa, jeden wykonawca </span>
            </h1>
          <p class="font-normal text-2xl line-height-3 md:mt-3 text-gray-700" style="max-width:900px;">Zespół AR Team realizuje prace związane z wykończeniem wnętrz, remontami oraz usługami budowlanymi. 
            Każdy projekt wykonujemy z najwyższą starannością i dbałością o detale. Nasze doświadczenie budowane przez wiele lat pracy w Polsce i Ukrainie oraz staranny dobór niezawodnych materiałów gwarantują rezultaty na najwyższym poziomie.   </p>
          <div class="flex flex-row flex-wrap mt-5">
            <div class="flex text-900 flex-column">
                <div class="p-2 flex flex-row flex-nowrap">
                    <i class="pi pi-fw pi-check text-xl  text-gray-700 mr-2"></i>
                    <span class="text-xl text-gray-700  line-height-3">Tynkowanie, szpachlowanie, malowanie</span>
                </div>
                <div class="p-2 flex flex-row flex-nowrap">
                    <i class="pi pi-fw pi-check text-xl  text-gray-700 mr-2"></i>
                    <span class="text-xl text-gray-700 white-space-nowrap line-height-3">Hydraulika, elektryka</span>
                </div>
                <div class="p-2 flex flex-row flex-nowrap">
                    <i class="pi pi-fw pi-check text-xl  text-gray-700 mr-2"></i>
                    <span class="text-xl text-gray-700 white-space-nowrap line-height-3">Kabiny prysznicowe</span>
                </div>
                <div class="p-2 flex flex-row flex-nowrap">
                    <i class="pi pi-fw pi-check text-xl  text-gray-700 mr-2"></i>
                    <span class="text-xl text-gray-700 white-space-nowrap line-height-3">Baterie podtynkowe</span>
                </div>
                <div class="p-2 flex flex-row flex-nowrap">
                    <i class="pi pi-fw pi-check text-xl  text-gray-700 mr-2"></i>
                    <span class="text-xl text-gray-700 white-space-nowrap line-height-3">Zabudowy GK</span>
                </div>
                <div class="p-2 flex flex-row flex-nowrap">
                    <i class="pi pi-fw pi-check text-xl  text-gray-700 mr-2"></i>
                    <span class="text-xl text-gray-700 white-space-nowrap line-height-3">Glazura, terakota</span>
                </div>
                <div class="p-2 flex flex-row flex-nowrap">
                    <i class="pi pi-fw pi-check text-xl  text-gray-700 mr-2"></i>
                    <span class="text-xl text-gray-700 white-space-nowrap line-height-3">Układanie parkietu</span>
                </div>
            </div>
            <div class="flex text-900 flex-column">
                <div class="p-2 flex flex-row flex-nowrap">
                    <i class="pi pi-fw pi-check text-xl  text-gray-700 mr-2"></i>
                    <span class="text-xl text-gray-700 line-height-3">Montaż listw przypodłogowych oraz sufitowych</span>
                </div>
                <div class="p-2 flex flex-row flex-nowrap">
                    <i class="pi pi-fw pi-check text-xl  text-gray-700 mr-2"></i>
                    <span class="text-xl text-gray-700 line-height-3">Montaż drzwi zewnętrznych oraz wewnętrznych</span>
                </div>
                <div class="p-2 flex flex-row flex-nowrap">
                    <i class="pi pi-fw pi-check text-xl  text-gray-700 mr-2"></i>
                    <span class="text-xl text-gray-700 white-space-nowrap line-height-3">Baterie podtynkowe</span>
                </div>
                <div class="p-2 flex flex-row flex-nowrap">
                    <i class="pi pi-fw pi-check text-xl  text-gray-700 mr-2"></i>
                    <span class="text-xl text-gray-700  line-height-3">Montaż sufitów podwieszanych oraz naciąganych</span>
                </div>
                <div class="p-2 flex flex-row flex-nowrap">
                    <i class="pi pi-fw pi-check text-xl  text-gray-700 mr-2"></i>
                    <span class="text-xl text-gray-700 white-space-nowrap line-height-3">Oświetlenie</span>
                </div>
                <div class="p-2 flex flex-row flex-nowrap">
                    <i class="pi pi-fw pi-check text-xl  text-gray-700 mr-2"></i>
                    <span class="text-xl text-gray-700 white-space-nowrap line-height-3">Meble na wymiar</span>
                </div>
            </div>
          </div>
          <Button label="Sprawdź nasze realizacje!" @click="$emit('products')" style="background-color:rgb(103 58 183);color:rgb(255 255 255) !important;" class="p-button-rounded text-xl border-none mt-5 font-normal text-white line-height-3 px-3"></Button>
      </div>
  </div>
</template>
<script>
import Button from 'primevue/button/sfc'
export default {
    name:"AboutUs",
    components:{
      Button,
    },
    emits: ['products']
}
</script>
<style lang="scss" scoped>


    .custom-marker {
    display: flex;
    width: 2rem;
    height: 2rem;
    align-items: center;
    justify-content: center;
    color: var(--surface-ground);
    border-radius: 50%;
    z-index: 1;
}
:deep(.p-timeline-event-content)
:deep(.p-timeline-event-opposite) {
    line-height: 1;
}
.text-gray-own{
  color: rgb(33 33 33) !important;
}

</style>