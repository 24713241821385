<template>
  <div id="main-container">
      <ScrollTop style="background-color:rgb(103 58 183); bottom: calc(5rem + 5px) !important" />
      <Home />
  </div>
</template>
<script>
import ScrollTop from 'primevue/scrolltop/sfc';
import Home from '@/views/Home.vue'
export default {
  name: "App",
  components:{
    ScrollTop,
    Home
  },
}
</script>

<style lang="scss">
.gallery-full{
  .p-galleria-content,
  .p-galleria-item-wrapper,
  .p-galleria-item-container,
  .p-galleria-item{
    display: flex;
    flex-direction: column;
    min-height: 0;
  };
  .p-galleria-close{
    z-index: 1;
  }
}
html{
    font-size: 14px !important;
}
body {
  margin: 0;
  padding: 0;
  min-width: 340px;
  overflow-x: hidden;
}
.p-scrolltop .p-scrolltop-icon{
  color:rgb(255 255 255) !important;
}
* {
  box-sizing: border-box;
}
#main-container {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-weight: 400;
  color: var(--text-color);
  //background-color: #eff3f8 !important;
   background: linear-gradient(0deg,hsla(0,0%,100%,.2),hsla(0,0%,100%,.2)),radial-gradient(77.36% 256.97% at 77.36% 57.52%,#eeefaf 0,#c3e3fa 100%);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
</style>
